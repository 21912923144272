import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import Toast from "react-bootstrap/Toast"
import LoadingModal from "../components/loading-modal/LoadingModal"

export default () => {
  const [fields, setFields] = useState({
    email: "",
    password: ""
  })

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [failure, setFailure] = useState(false)

  const toggleSuccess = () => setSuccess(!success)
  const toggleInvalid = () => setInvalid(!invalid)
  const toggleFailure = () => setFailure(!failure)

  const handleChange = e => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    })
  }

  const isEmail = email => {
    var emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailReg.test(email)
  }

  const handleSubmit = async e => {
    if (isEmail(fields.email) && fields.password) {
      console.log("submit data...")
      setLoading(true)
      try {
        const res = await axios.post(
          process.env.GATSBY_AUTH_API + "/signup",
          {
            signupData: { ...fields, system: 'windows', gatewayName: "MyGateway"},
          }
        )

        const res2 = await axios.post(
          process.env.GATSBY_MAIL_API + "/contact",
          {
            contactData: { email: fields.email, message: "User registered." },
          }
        )

        console.log(res)

        setLoading(false)
        // navigate("/")
        window.location.href = process.env.GATSBY_ODS_URL
      } catch (err) {
        setLoading(false)
        setFailure(true)
        console.log(err)
      }
    } else {
      console.log("Invalid fields..")
      setInvalid(true)
    }
  }

  return (
    <div className="container-fluid contacto">
      <div className="container" style={{ marginTop: "2rem" }}>
        <div className="bg-white wow fadeInUp">
          <div className="encabezadocontacto">
            <i className="las la-user-plus"></i>
          </div>
          <h1 className="text-center">Sign Up</h1>
          <form>
            <div className="row">
              <div className="col-sm-12 form-group">
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  placeholder="Your email"
                  value={fields.email}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-12 form-group">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Your password"
                  value={fields.password}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12 col-sm-12 form-group text-center">
                <button
                  className="btn btn-primary mt-3"
                  onClick={e => {
                    e.preventDefault()
                    handleSubmit(e)
                  }}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <LoadingModal show={loading} />
      <Toast
        show={invalid}
        onClose={toggleInvalid}
        delay={3000}
        autohide
        style={{
          position: "absolute",
          bottom: 50,
          left: "50%",
          transform: "translate(-50%, 0)",
          backgroundColor: "#dc3545",
          color: "white",
        }}
      >
        <Toast.Body>Invalid sign up data</Toast.Body>
      </Toast>
      <Toast
        show={failure}
        onClose={toggleFailure}
        delay={3000}
        autohide
        style={{
          position: "absolute",
          bottom: 50,
          left: "50%",
          transform: "translate(-50%, 0)",
          backgroundColor: "#dc3545",
          color: "white",
        }}
      >
        <Toast.Body>Can't signup the user</Toast.Body>
      </Toast>
      <Toast
        show={success}
        onClose={toggleSuccess}
        delay={3000}
        autohide
        style={{
          position: "absolute",
          bottom: 50,
          left: "50%",
          transform: "translate(-50%, 0)",
          backgroundColor: "#28a745",
          color: "#343a40",
        }}
      >
        <Toast.Body>¡Succesful sign up!</Toast.Body>
      </Toast>
    </div>
  )
}
